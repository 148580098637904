import { Controller } from "stimulus"

let debounce = require('lodash/debounce')
export default class extends Controller {

  static targets = ['questionOption']

  initialize(){
    this.debouncedFilter = debounce(this.setFilter, 1000, { maxWait: 3000 }).bind(this)
  }

  setFilter(){
      const urlParams = new URLSearchParams(window.location.search);

      urlParams.set(this.questionOptionTarget.dataset.questionId, this.questionOptionTarget.value);

      window.location.search = urlParams;
  }

}