// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import "channels"

window.Rails = Rails

import 'alpine-hotwire-turbo-adapter'

require("alpinejs")

require("stylesheets/application.scss")

Rails.start()

require("controllers");

require("@rails/activestorage").start()