import { Controller } from "stimulus"
export default class extends Controller {

    static targets = ['submit', 'field']

    connect(){
        this.checkForm()
    }

    reset() {
        this.element.reset()
        this.checkForm()
    }

    checkForm(){
        this.submitTarget.disabled = false

        this.fieldTargets.forEach(element => {
            if((element.dataset.required == 'true') && !element.value){
                this.submitTarget.disabled = true
            }
        })

      }

}