import { Controller } from 'stimulus'

export default class extends Controller{

  static targets = ["notification"]

  connect(){
    this.show()
  }

  show(){
    this.notificationTarget.classList.add('animate__fadeInDown', 'animate__fast')

    setTimeout(() => {
      this.hide()
    }, 4000)
  }

  hide(){
    this.notificationTarget.classList.add('animate__fadeOutUp', 'animate__faster')
    this.notificationTarget.addEventListener('animationend', () => {
      this.notificationTarget.hidden = true
    })
  }
  
}