import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['selectList']

  // Defining the action method
  changeList(event) {
    const listId = event.currentTarget.value; // Get the selected value from the selectList
    if(listId) {
      window.location.pathname = `/lists/${listId}`;
    }
  }

  changeStatus(event) {
    event.currentTarget.form.submit(); // Submit the enclosing form of the select tag
  }

}