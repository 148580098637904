import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ["hidden"]

  toggle(){
    if(this.hiddenTarget.classList.contains('hidden')){
      this.hiddenTarget.classList.remove('hidden')
    } else{
      this.hiddenTarget.classList.add('hidden')
    }
  }

  toggle() {
  
    this.hiddenTarget.classList.toggle('hidden');

    this.hackForShare();
  
  }

  hackForShare(){
    // This function is added to allow for the share function in List to not load the modal after refresh

    let url = new URL(window.location.href);
    let shareParam = url.searchParams.get('share');
    if (shareParam === 'true') {
      url.searchParams.set('share', 'false');
    }
    window.history.pushState({}, '', url);
  }

}