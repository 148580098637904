import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['sectionForm', 'questionsSelect', 'question', 'filterBaseValue', 'searchString']

  connect(){
  }

  findQuestions(){
    Rails.fire(this.sectionFormTarget, 'submit')
  }

  addQuestions(){
    const [data, status, xhr] = event.detail
    this.questionsSelectTarget.innerHTML = xhr.response
  }

  chooseFilter(){
    this.setFilter(this.questionTarget.value, this.filterBaseValueTarget.value)
  }

  setFilter(key, value){
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set(key, value);

    window.location.search = urlParams;

  }

  setStringFilter(){
    const urlParams = new URLSearchParams(window.location.search);

    urlParams.set("string", this.searchStringTarget.value);

    window.location.search = urlParams;

  }

}